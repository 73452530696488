<template>
  <b-card title="redirecting">
  </b-card>
</template>

<script>
import ApiService from '@/common/api.service'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
  },
  created() {
    ApiService.get(`account/login/customer/${this.$router.currentRoute.params.code}`, {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          const userData = response.result
          useJwt.setToken(response.result.token)
          useJwt.setRefreshToken(response.result.token)
          useJwt.setUserData(userData)
          this.$store.commit('account/setIsLoggedIn', true);
          
          this.$router.push(getHomeRouteForLoggedInUser(userData.userRole))
            .then(() => {
            })
        } else {
          AlertService.warning(this, this.$t(response.message))
        }
      });
  },
}
</script>
